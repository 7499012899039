<template>
    <el-dialog title="SEO管理导入" :visible.sync="importFormVisible" v-loading="importFormLoading" width="400px">
        <el-upload
            drag
            accept=".xlsx"
            :action="importUrl"
            :on-success="onImportSuccess"
            :on-progress="onImportProgress"
            :on-error="onImportError"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headerData"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将excel文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
    </el-dialog>
</template>
<script>
import cache from '@/utils/cache';
import Config from '@/utils/config';
export default {
    name: 'SeoImport',
    data() {
        return {
            importFormLoading: false,
            importFormVisible: false,
            importUrl: process.env.VUE_APP_URL + '/official/v1.0/seo/import',
            headerData: {
                Authorization: cache.getStr(Config.accessToken),
                tenantid: cache.get(Config.currentTenant) == null ? 1001001000 : cache.get(Config.currentTenant).tenant_id,
                // 'X-User':
                //     'ew0KICAiaXNzIjogImh0dHBzOi8vdGVzdC5hY2NvdW50LmFpZ29zdGFyLmNvbTozNDQzIiwNCiAgInVzZXJfaWQiOiAiMTAwMDAwMDAxMDEyOTYiLA0KICAib3JnX2lkIjogIjEwMDAiLA0KICAib3JnX3VzZXJfaWQiOiAiMTAxMjk2IiwNCiAgInVzZXJuYW1lIjogImJvYm8iLA0KICAibGVnYWxfbmFtZSI6ICLms6Lms6IiLA0KICAicGhvbmVfbnVtYmVyIjogIiIsDQogICJlbWFpbCI6ICIiLA0KICAiY291bnRyeV9jb2RlIjogIkNOIg0KfQ==',
                // 'X-Iss': 'https://test.uc.aigostar.com:3443'
            }
        };
    },
    created: function () {},
    methods: {
        onImportProgress(event, file, fileList) {
            this.importFormLoading = true;
        },
        onImportSuccess(response, file, fileList) {
            this.importFormVisible = false;
            this.importFormLoading = false;
            this.$message.success('文件导入成功');
            this.$parent.handleQuery();
        },
        onImportError(error, file, fileList) {
            var message = JSON.parse(error.message);
            console.log(message);
            this.$message({
                showClose: false,
                // message: '文件导入失败，请检查文件内容',
                message: message.message,
                type: 'error'
            });
            this.importFormLoading = false;
            this.$common.showNotifyError(JSON.parse(error.message));
        },
        beforeUpload(file){
            var index = file.name.lastIndexOf('.')
            var fileType = file.name.substring(index,file.name.length);
            console.log(fileType);
            if(fileType != '.xlsx'){
                this.$message({
                showClose: false,
                message: '文件类型错误',
                type: 'error'
            });
                return false;
            }
        },
        show() {
            this.importFormVisible = true;
        }
    }
};
</script>
