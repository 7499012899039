<template>
    <div class="app-container">
        <div class="filter-container">
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'country_all'"
                placeholder="国家"
            ></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> 查询 </el-button>
            <div style="margin-top: 20px">
                <el-button class="filter-item" type="success" icon="el-icon-edit" @click="handleCreate"> 添加 </el-button>
            </div>
        </div>

        <el-table
            v-loading="listLoading"
            :data="list"
            @selection-change="selectionChange"
            border
            stripe
            style="width: 100%; margin-top: 20px"
        >
            <el-table-column label="ID" prop="countryid" align="center" width="200px">
                <template slot-scope="{ row }">
                    <span>{{ row.countryId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="国家" prop="countryCode" align="center">
                <template slot-scope="{ row }">
                    <dataText v-model="row.countryCode" :codeType="'country_all'"></dataText>
                </template>
            </el-table-column>
            <el-table-column label="图标" prop="icon" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="ossHost + scope.row.icon" fit="fit"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="语言" prop="lang1" align="center">
                <template slot-scope="{ row }">
                    <dataText v-model="row.lang1" :codeType="'lang'"></dataText>
                </template>
            </el-table-column>
            <el-table-column label="品牌" prop="description" align="center" width="250px">
                <template slot-scope="{ row }">
                    <el-tag v-for="item in row.countryParameterList" :key="item.countryParameterId" style="margin-left: 10px">
                        {{ item.brand }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createtime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" prop="updatetime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.updateTime == null ? '' : new Date(row.updateTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否启用" prop="status" align="center">
                <template slot-scope="{ row }">
                    <span>
                        <el-switch @change="changeStatus(row)" v-model="row.status" active-color="#13ce66"> </el-switch>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                    <el-button size="mini" @click="handleConfig(row.countryId)" class="table-inner-button">配置</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <country-add ref="countryAdd" @created="doQueryList({ pageNo: 1 })" />

        <countryConfig ref="countryConfig" @restOpen="restOpendialog" />
    </div>
</template>

<script>
import countryAdd from './saveOrUpdate';
import DataSelect from '@/components/common/DataSelect';
import countryConfig from '@/views/country/countryConfig';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';

export default {
    name: 'CountryTable',
    components: {
        countryAdd,
        DataSelect,
        countryConfig,
        dataSelect,
        dataText
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            list: [],
            listLoading: true,
            query: {
                countryCode: null,
                page: 1,
                limit: 10,
            },
            total:null,
            selectItems: [],
            counrtys: [],
            langs: [],
            counrtylangs: [],
            checkedBrand: [],
            brands: []
        };
    },
    mounted() {
        this.getDictItems();
        this.doQueryList({ pageNo: 1 });
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        restOpendialog(data) {
            this.handleConfig(data);
        },
        handleConfig(countryId) {
            this.$store.dispatch('getCountryInfo', countryId).then((res) => {
                console.log(res);
                this.$refs.countryConfig.countryParameterList = res.countryParameterList;

                this.$refs.countryConfig.form = res.countryParameterList[0];
                this.$refs.countryConfig.countryId = countryId;
                this.$refs.countryConfig.activeName = '0';
                let arr = [];
                for (const key in res.countryParameterList) {
                    const element = res.countryParameterList[key];
                    arr.push({
                        title: element.brand,
                        name: key
                    });
                }

                this.$refs.countryConfig.editableTabs = arr;
                this.$refs.countryConfig.dialogFormVisible = true;
            });
        },
        changeStatus(row) {
            let param = {
                status: row.status,
                id: row.countryId
            };

            this.$store
                .dispatch('changeCountryStatus', param)
                .then((res) => {
                    this.$message.success('状态编辑成功');
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            this.listLoading = true;

            this.$store
                .dispatch('getcountryList', this.query)
                .then((res) => {
                    this.list = res.list;
                    
                    this.list.forEach((element) => {                  
                        for(var item of this.counrtylangs){
                            if(item.code == element.countryCode){
                                element.lang1 = item.name;
                                return;
                            }
                        }
                    });

                    this.total = res.total;
                    console.log('====', this.list);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$confirm('是否确认继续删除数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteCountry', row.countryId).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.doQueryList({ pageNo: 1 });
                });
            });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.countryAdd.handleCreate();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            console.log('编辑row', row);
            this.$refs.countryAdd.handleEdit();
            this.$refs.countryAdd.form.countryId = row.countryId;
            this.$refs.countryAdd.form.countryCode = row.countryCode;
            this.$refs.countryAdd.form.icon = row.icon;
            this.$refs.countryAdd.changeCountry(row.countryCode);
            let arr = [];
            if (row.countryParameterList != null && row.countryParameterList.length != 0) {
                row.countryParameterList.forEach((element) => {
                    arr.push(element.brand);
                });
            }
            this.$refs.countryAdd.imageUrl = row.icon;
            this.$refs.countryAdd.reloadimageUrl = row.icon;
            this.$refs.countryAdd.checkedBrand = arr;
            this.$refs.countryAdd.form.status = row.status;

            console.log('编辑', this.$refs.countryAdd.form);
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'country_all' }).then((res) => {
                if (res != null) {
                    _this.counrtys = res;
                }
                console.log(_this.counrtys);
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null) {
                    _this.langs = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) {
                    _this.counrtylangs = res;
                }
            });

            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brands = res;
                }
            });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
