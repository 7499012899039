var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formData", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮件模板编号",
                prop: "emailTemplateCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "300px" },
                  attrs: { clearable: "", placeholder: "请选择模板类型" },
                  on: { change: _vm.handleEmailTemplateCodeChange },
                  model: {
                    value: _vm.formData.emailTemplateCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "emailTemplateCode", $$v)
                    },
                    expression: "formData.emailTemplateCode"
                  }
                },
                _vm._l(_vm.emailTemplateCodeDicts, function(item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "发送对象",
                prop: "sendEmail",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                staticStyle: { width: "88%" },
                attrs: { readonly: "" },
                model: {
                  value: _vm.formData.sendEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sendEmail", $$v)
                  },
                  expression: "formData.sendEmail"
                }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.handleExport }
                },
                [_vm._v("导出选中")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "模板语言",
                prop: "lang",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "300px" },
                  attrs: { clearable: "", placeholder: "请选择模板语言" },
                  model: {
                    value: _vm.formData.lang,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "lang", $$v)
                    },
                    expression: "formData.lang"
                  }
                },
                _vm._l(_vm.langDicts, function(item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮件标题",
                prop: "emailTitle",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  maxlength: 200,
                  "show-word-limit": "",
                  placeholder: "请填写邮件标题（请填入对应翻译）"
                },
                model: {
                  value: _vm.formData.emailTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "emailTitle", $$v)
                  },
                  expression: "formData.emailTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮件内容",
                prop: "emailContent",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请填写邮件内容（请填入对应翻译）"
                },
                model: {
                  value: _vm.formData.emailContent,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "emailContent", $$v)
                  },
                  expression: "formData.emailContent"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "备注",
                prop: "remark",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  type: "textarea",
                  rows: 5,
                  maxlength: 500,
                  "show-word-limit": "",
                  placeholder: "请填写备注"
                },
                model: {
                  value: _vm.formData.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }