<template>
    <div class="custom-tree-container">
        <el-container>
            <!-- <el-card class="box-card" style="margin-top:10px"> -->
            <el-header style="height: auto; margin-top: 10px">
                <el-form :inline="true" @keyup.enter.native="getTableList">
                    <el-row>
                        <!-- <el-form-item label="">
                            <dataSelect v-model="searchData.platform" :codeType="'platform'" placeholder="平台"></dataSelect>
                        </el-form-item> -->
                        <el-form-item prop="title">
                            <el-input
                                placeholder="标题"
                                :clearable="true"
                                v-model="query.title"
                                style="width: 180px"
                                oninput="value=value.replace(/[%]/g,'')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="typeTitle">
                            <el-input
                                placeholder="栏目"
                                :clearable="true"
                                v-model="query.typeTitle"
                                style="width: 180px; margin-left: 10px"
                                oninput="value=value.replace(/[%]/g,'')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="typeTitle">
                            <el-select
                                v-model="query.brandCode"
                                :multiple="false"
                                :clearable="true"
                                placeholder="品牌"
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                            >
                                <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select
                                v-model="query.showButtom"
                                placeholder="是否在网站底部显示"
                                clearable
                                style="width: 180px; margin-left: 10px"
                                class="filter-item"
                            >
                                <el-option label="是" value="true"></el-option>
                                <el-option label="否" value="false"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="small" type="primary" icon="el-icon-search" @click="tree()">查询</el-button>
                            <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small"
                            >
                                重置
                            </el-button>
                        </el-form-item>
                    </el-row>
                    <el-row style="margin-bottom: 10px">
                        <el-button-group>
                            <el-button type="primary" @click="addInformationType(0)" icon="el-icon-plus">添加</el-button>
                        </el-button-group>
                    </el-row>
                </el-form>
            </el-header>
            <el-main>
                <el-table
                    v-if="refreshTable"
                    id="mainTable"
                    :data="data"
                    style="width: 100%"
                    border
                    :default-expand-all="expand_all"
                    highlight-current-row
                    element-loading-background="rgba(255, 255, 255, 1)"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                    element-loading-text="Loading"
                    element-loading-spinner="el-icon-loading"
                    row-key="informationTypeId"
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                >
                    <el-table-column :show-overflow-tooltip="true" prop="typeTitle" label="栏目层级"></el-table-column>

                    <el-table-column prop="countryCodes" label="展示国家" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(item, index) in scope.row.countryCodes" :key="index">
                                {{ item }}
                                <span v-if="scope.row.countryCodes.length != 1 && index != scope.row.countryCodes.length - 1">/</span>
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column :show-overflow-tooltip="true" prop="title" label="标题"></el-table-column>
                    <el-table-column prop="brandCodes" label="品牌" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(item, index) in scope.row.brandCodes" :key="index">
                                {{ item }}
                                <span v-if="scope.row.brandCodes.length != 1 && index != scope.row.brandCodes.length - 1">/</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" width="150" label="是否在网站底部显示">
                        <template slot-scope="{ row }">
                            <span v-if="row.showButtom == true">{{ '是' }}</span>
                            <span v-if="row.showButtom == false">{{ '否' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" prop="sort" label="排序">
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.sort"
                                :min="0"
                                οnkeyup="value=value.replace(/[^\d]/g,'')"
                                oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                                @keyup.enter.native="putSortInformation(scope.row)"
                                placeholder="请输入内容"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip :formatter="formatDate"> </el-table-column>
                    <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip :formatter="formatDate"> </el-table-column>

                    <el-table-column label="操作" width="280">
                        <template slot-scope="{ row }">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="addInformationType(row.informationTypeId)"
                                class="table-inner-button"
                                >添加子栏目</el-button
                            >
                            <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                            <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
            <!-- 新建表单 -->
            <el-dialog
                :title="formTitle"
                :visible.sync="addVisible"
                @close="closeForm()"
                v-if="addVisible"
                :close-on-click-modal="false"
                width="950px"
            >
                <el-form
                    ref="dataForm"
                    :rules="formRules"
                    :model="addInformation"
                    label-position="left"
                    size="small"
                    label-width="100px"
                    style="width: 400px; margin-left: 50px"
                >
                    <el-form-item label="展示国家" prop="countryCodes">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                        <el-checkbox-group v-model="addInformation.countryCodes" @change="handleCheckedCitiesChange" style="width: 600px">
                            <el-checkbox v-for="country in countrysDicts" :label="country.code" :key="country.code"
                                >{{ country.name }}({{ country.code }})</el-checkbox
                            >
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="品牌" prop="brandCodes">
                        <dataSelect
                            style="width: 180px"
                            class="filter-item"
                            v-model="addInformation.brandCodes"
                            clearable
                            :multiple="true"
                            :codeType="'brand'"
                            placeholder="品牌"
                        ></dataSelect>
                    </el-form-item>
                    <el-form-item label="网站底部显示" prop="status">
                        <el-switch v-model="addInformation.showButtom" active-color="#13ce669"></el-switch>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="addInformation.sort"
                            style="width: 100%"
                            :min="0"
                            oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="多语言配置" style="width: 800px">
                        <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                            <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                                {{ item.name }}
                            </el-menu-item>
                        </el-menu>
                        <div>
                            <span>文章标题：</span>
                            <el-input
                                style="margin-top: 20px; width: 450px"
                                v-model="title"
                                placeholder="请输入文章标题"
                                label="文章标题"
                            ></el-input>
                        </div>
                        <div>
                            <span>栏目标题：</span>
                            <el-input
                                style="margin-top: 20px; width: 450px"
                                v-model="typeTitle"
                                placeholder="请输入栏目标题"
                                label="栏目标题"
                            ></el-input>
                        </div>
                        <div style="margin-top: 20px">
                            <span>内容：</span>
                            <wangEditer ref="wangEditer" :conten="contentValue" style="margin-top: 10px" />
                        </div>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeForm()"> 取消 </el-button>
                    <el-button type="primary" @click="handleSubmit()"> 确认 </el-button>
                </div>
            </el-dialog>
            <!-- </el-card> -->
        </el-container>
    </div>
</template>
<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';
import wangEditer from '@/components/common/WangEditer/index';

function initAddBean() {
    const addBean = {
        informationId: null,
        informationTypeId: null,
        parentId: null,
        showButtom: false,
        sort: 0,
        countryCodes: [],
        brandCodes: [],
        informationTypeLangList: [],
        contents: []
    };
    return addBean;
}

export default {
    name: 'informationType',
    components: {
        dataSelect,
        dataText,
        moment,
        wangEditer
    },
    data() {
        return {
            expand_all: false,
            refreshTable: true,
            data: [],
            query: {
                title: '',
                showButtom: null,
                typeTitle: '',
                brandCode: ''
            },
            formTitle: '',
            old: initAddBean(),
            addInformation: initAddBean(),
            addVisible: false,
            checkAll: false,
            isIndeterminate: true,
            //富文本内容的主要参数
            contentValue: '',
            contentIndex: 'zh',
            typeTitle: '',
            title: '',
            langsArr: [],
            langs: [],
            //字典
            countrysDicts: [],
            countryLang: [],
            brandDicts: [],
            formRules: {
                brandCodes: [{ required: true, message: '请选择品牌', trigger: 'change' }],
                countryCodes: [{ required: true, message: '请选择国家', trigger: 'change' }]
            }
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.tree();
        this.getDictItems();
    },
    methods: {
        /**
         * 修改排序
         */
        putSortInformation(row) {
            const str = '^(\\d+)\\.(\\d{0}).*$';
            const reg = new RegExp(str);
            this.$store
                .dispatch('putSortInformation', {
                    id: row.informationTypeId,
                    sort: row.sort ? row.sort : 0
                })
                .then((res) => {
                    this.tree();
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                });
        },
        tree() {
            this.$store.dispatch('tree', this.query).then((res) => {
                this.data = res;
                this.handleOpen();
                console.log(res);
            });
        },
        //添加操作
        addInformationType(parentId) {
            this.formTitle = '新增';
            this.resetForm();
            this.isIndeterminate = false;
            this.addInformation.parentId = parentId;
            this.addVisible = true;
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle = '编辑';
            this.$store
                .dispatch('getInformation', { informationId: row.informationId, informationTypeId: row.informationTypeId })
                .then((res) => {
                    console.log(res);
                    this.old = res;
                    this.resetForm();
                    this.isIndeterminate = true;
                    //赋值
                    this.addInformation.brandCodes = this.old.brandCodes;
                    this.addInformation.countryCodes = this.old.countryCodes;
                    this.addInformation.informationId = row.informationId;
                    this.addInformation.informationTypeId = row.informationTypeId;
                    this.addInformation.parentId = this.old.parentId;
                    this.addInformation.showButtom = this.old.showButtom;
                    this.addInformation.sort = this.old.sort;
                    this.addInformation.informationTypeLangList = this.old.informationTypeLangList;
                    this.addInformation.contents = this.old.contents;
                    //初始化多语言
                    for (var item of this.addInformation.informationTypeLangList) {
                        for (const key in this.langsArr) {
                            if (item.lang == this.langsArr[key].lang) {
                                this.langsArr[key].typeTitle = item.title;
                            }
                        }
                    }
                    for (var item of this.addInformation.contents) {
                        for (const key in this.langsArr) {
                            if (item.lang == this.langsArr[key].lang) {
                                this.langsArr[key].title = item.title;
                                this.langsArr[key].content = item.content;
                            }
                        }
                    }
                    console.log(this.langsArr);
                    //赋值多语言信息列表
                    var cont = '';
                    for (var item of this.langsArr) {
                        if (item.lang == this.contentIndex) {
                            this.title = item.title;
                            this.typeTitle = item.typeTitle;
                            cont = item.content;
                        }
                    }
                    setTimeout(() => {
                        this.$refs['wangEditer'].initData(cont);
                    }, 200);
                    this.addVisible = true;
                });
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.checkAll = false;
            this.addInformation = initAddBean();
            this.contentIndex = 'zh';
            this.title = '';
            this.typeTitle = '';
            this.contentValue = '';
            this.langsArr = [];
            this.initLangsArr();
        },
        /**
         * 重置多语言
         */
        initLangsArr() {
            for (var item of this.langs) {
                let param = {
                    lang: item.code,
                    typeTitle: '',
                    content: '',
                    title: ''
                };
                this.langsArr.push(param);
            }
        },
        handleContentSelect(key, keyPath) {
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml().slice(0, 9999);
            }

            let titleResult = '';
            if (this.title != undefined) {
                titleResult = this.title.slice(0, 199);
            }

            let typeTitleResult = '';
            if (this.typeTitle != undefined) {
                typeTitleResult = this.typeTitle.slice(0, 199);
            }

            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].title = titleResult;
                    this.langsArr[index].content = result;
                    this.langsArr[index].typeTitle = typeTitleResult;
                }
            }

            //更新富文本内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.typeTitle = item.typeTitle;
                    this.$refs['wangEditer'].initData(item.content);
                }
            }
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            //取富文本值
            let result = this.$refs['wangEditer'].getHtml();

            //当前选项多语言信息赋值
            for (const key in this.langsArr) {
                if (this.langsArr[key].lang == this.contentIndex) {
                    this.langsArr[key].title = this.title.slice(0, 199);
                    this.langsArr[key].typeTitle = this.typeTitle.slice(0, 199);
                    this.langsArr[key].content = result.slice(0, 9999);
                }
            }

            //多语言校验
            var countryLangs = [];
            for (var item of this.addInformation.countryCodes) {
                for (var langItem of this.countryLang) {
                    if (item == langItem.code && item != 'OT') {
                        countryLangs.push(langItem.name);
                    }
                }
            }
            for (var item of this.langsArr) {
                if (countryLangs.includes(item.lang) && item.title == '') {
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message: '所选国家文章标题必填',
                        type: 'warning'
                    });
                    return;
                }
                if (countryLangs.includes(item.lang) && item.content == '') {
                    countryLangs = [];

                    this.$message({
                        showClose: false,
                        message: '所选国家内容必填',
                        type: 'warning'
                    });
                    return;
                }
                if (countryLangs.includes(item.lang) && item.typeTitle == '') {
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message: '所选国家栏目标题必填',
                        type: 'warning'
                    });
                    return;
                }
            }

            let typeArr = [];
            let contentArr = [];

            for (var item of this.langsArr) {
                //栏目赋值
                let typeParam = {
                    title: item.typeTitle,
                    lang: item.lang
                };
                typeArr.push(typeParam);

                //文章内容标题赋值
                let contentParam = {
                    title: item.title,
                    content: item.content,
                    lang: item.lang
                };
                contentArr.push(contentParam);
            }

            this.addInformation.informationTypeLangList = typeArr;
            this.addInformation.contents = contentArr;

            console.log(this.addInformation);

            if (this.formTitle == '新增') {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addInformation', this.addInformation).then((data) => {
                            this.addVisible = false;
                            this.tree();
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('editInformation', this.addInformation).then((data) => {
                            this.addVisible = false;
                            this.tree();
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '编辑失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        //展开
        handleOpen() {
            this.refreshTable = false;
            this.expand_all = true;
            this.$nextTick(() => {
                this.refreshTable = true;
            });
        },
        closeForm() {
            this.addVisible = false;
            this.tree();
        },
        cleanQuery() {
            this.query.title = '';
            this.query.showButtom = null;
            this.query.typeTitle = '';
            this.query.brandCode = '';
            this.tree();
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteInformation', { id: row.informationTypeId }).then((res) => {
                        this.tree();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'country_ot' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        //国家全选
        handleCheckAllChange(val) {
            if (val) {
                this.addInformation.countryCodes = [];
                for (var item of this.countrysDicts) {
                    this.addInformation.countryCodes.push(item.code);
                }
            } else {
                this.addInformation.countryCodes = [];
            }

            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.countrysDicts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.countrysDicts.length;
            console.log(this.isIndeterminate);
        }
    }
};
</script>

<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>
