<template>
    <el-dialog :title="formTitle" width="600px" :visible.sync="formVisible">
        <el-form ref="dataForm" :rules="formRules" :model="form" size="small" label-width="100px">
            <el-form-item label="品牌" prop="brand">
                <dataSelect
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.brand"
                    clearable
                    :codeType="'brand'"
                    placeholder="品牌"
                ></dataSelect>
            </el-form-item>
            <el-form-item label="页面名称" prop="name">
                <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="页面URL" prop="url">
                <el-input v-model="form.url" />
            </el-form-item>
            <el-form-item label="语言" prop="lang">
                <dataSelect
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.lang"
                    clearable
                    :codeType="'lang'"
                    placeholder="语言"
                ></dataSelect>
            </el-form-item>
            <el-form-item label="页面标题1" prop="title">
                <el-input type="textarea" v-model="form.title" oninput="if(value.length>200)value=value.slice(0,200)"/>
            </el-form-item>
            <el-form-item label="页面关键字" prop="keyword">
                <el-input type="textarea" v-model="form.keyWord" oninput="if(value.length>200)value=value.slice(0,200)"/>
            </el-form-item>
            <el-form-item label="页面描述" prop="description">
                <el-input type="textarea" v-model="form.description" oninput="if(value.length>1000)value=value.slice(0,1000)" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false"> 取消 </el-button>
            <el-button type="primary" @click="doCreate()"> 确认 </el-button>
        </div>
    </el-dialog>
</template>

<script>
import DataSelect from '@/components/common/DataSelect';
function initFormBean() {
    const formBean = {
        name: null,
        brand: null,
        url: null,
        lang: null,
        title: "",
        keyWord: "",
        description: ""
    };
    return formBean;
}

export default {
    components: {
        DataSelect
    },
    name: 'SeoAdd',
    data() {
        return {
            form: {},
            formVisible: false,
            formTitle:'',
            formRules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { max: 20, message: '长度不能超过20个字符', trigger: 'blur' }
                ],
                brand: [
                    { required: true, message: '请输入品牌', trigger: 'change' },
                    { max: 50, message: '长度不能超过50个字符', trigger: 'change' }
                ],
                url: [
                    { required: true, message: '请输入页面URL', trigger: 'blur' },
                    { max: 100, message: '长度不能超过100个字符', trigger: 'blur' }
                ],
                lang: [
                    { required: true, message: '请输入语言', trigger: 'change' },
                    { max: 2, message: '长度不能超过2个字符', trigger: 'change' }
                ]
                // title: [
                //     { required: true, message: '请输入页面标题', trigger: 'blur' },
                //     { max: 100, message: '长度不能超过100个字符', trigger: 'blur' }
                // ],
                // keyword: [
                //     { required: true, message: '请输入页面关键字', trigger: 'blur' },
                //     { max: 200, message: '长度不能超过200个字符', trigger: 'blur' }
                // ],
                // description: [
                //     { required: true, message: '请输入页面描述', trigger: 'blur' },
                //     { max: 500, message: '长度不能超过500个字符', trigger: 'blur' }
                // ]
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            this.form = initFormBean();
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.resetForm();
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        /**
         * 执行新建操作
         */
        doCreate() {
            this.$refs['dataForm'].validate().then(() => {
                if (this.form.id == null) {
                    this.$store.dispatch('createSEO', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success('操作成功');
                    });
                } else {
                    this.$store.dispatch('updateSEO', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success('操作成功');
                    });
                }
            });
        }
    }
};
</script>
