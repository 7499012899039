<template>
    <div style="display: flex; justify-content: flex-start; margin-top: 20px">
        <el-upload
            v-if="ossVideoUrl == ''"
            class="avatar-uploader"
            :action="ossInfo.ossSignature.host"
            :show-file-list="false"
            :auto-upload="false"
            list-type="picture-card"
            ref="uploadVideo"
            :on-progress="uploadVideoProcess"
            :on-change="handleChange2"
            :data="fileDataObj2"
            accept=".mp4"
        >
            <i v-if="videoUploadPercent == 0" class="el-icon-plus avatar-uploader-icon"></i>
            <el-progress
                v-else-if="videoUploadPercent > 0"
                type="circle"
                :percentage="videoUploadPercent"
                style="margin-top: 30px; width: 178px; height: 178px"
            ></el-progress>
        </el-upload>
        <video class="float-l" style="width: 70%" v-else :src="ossVideoUrl" controls="controls">您的浏览器不支持视频播放</video>
        <div style="margin-left: 20px; display: flex; justify-content: flex-start; align-items: flex-end">
            <span v-if="ossVideoUrl == ''">支持mp4格式，≤500MB</span>
            <el-button v-else type="primary" @click="backVideo" plain>删除</el-button>
        </div>
    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
export default {
    name: 'videoUpload',
    props: {
        video: String
    },
    mounted() {
        this.getOssSignature();
    },
    data() {
        return {
            ossVideoUrl: '',
            videoUploadPercent: 0,
            fileDataObj2: {},

            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },

            ossVideoUrl: this.video
        };
    },
    methods: {
        event() {
            this.$emit('event', this.ossVideoUrl);
        },
        //初始化文本内容
        initData(data) {
            console.log(data)
            if (data == undefined) {
                data = '';
            }
            this.ossVideoUrl = data;
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },
        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        },
        handleChange2(file, fileLis) {
            const isLt = file.size / 1024 / 1024 <= 500;
            if (!isLt) {
                this.$message.error('上传视频大小不能超过 500MB!');
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj2 = dataObj;
            setTimeout(() => {
                this.$refs.uploadVideo.submit();
                var x2js = new X2JS();
                var jsonObj = x2js.xml2js(file.response);
                console.log(jsonObj);
                let ossUrl = jsonObj.PostResponse.Location;
                this.ossVideoUrl = ossUrl;
                this.event();
            }, 500);
        },
        uploadVideoProcess(event, file, fileList) {
            this.videoFlag = true;
            this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
        },
        handleAvatarSuccessVideo(res, file) {
            var timestamp = new Date().getTime();
            var fileName = timestamp + '_' + parseInt(Math.round(Math.random() * 100));
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/fulltext/' + fileName + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature'],
                file: file
            };

            this.$store.dispatch('postOss', { params: dataObj, url: this.ossInfo.host }).then((res) => {
                //解析xml
                let x2js = new X2JS();
                let jsonObj = x2js.xml2js(res);
                let ossName = jsonObj.PostResponse.Key;
                this.videoUrl = URL.createObjectURL(file.raw);
                this.ossVideoUrl = jsonObj.PostResponse.Location;
            });
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2b'
                })
                .then((res) => {
                    console.log(res);
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        backVideo(){
				this.videoUploadPercent=0;
				this.ossVideoUrl='';
                //
                this.event();
		},
    }
};
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
