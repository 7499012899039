import { render, staticRenderFns } from "./countryConfig.vue?vue&type=template&id=38770e95&scoped=true&"
import script from "./countryConfig.vue?vue&type=script&lang=js&"
export * from "./countryConfig.vue?vue&type=script&lang=js&"
import style0 from "./countryConfig.vue?vue&type=style&index=0&id=38770e95&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38770e95",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.OfficialWebsiteAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38770e95')) {
      api.createRecord('38770e95', component.options)
    } else {
      api.reload('38770e95', component.options)
    }
    module.hot.accept("./countryConfig.vue?vue&type=template&id=38770e95&scoped=true&", function () {
      api.rerender('38770e95', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/country/countryConfig.vue"
export default component.exports