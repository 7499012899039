var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "flex-start",
        "margin-top": "20px"
      }
    },
    [
      _vm.ossVideoUrl == ""
        ? _c(
            "el-upload",
            {
              ref: "uploadVideo",
              staticClass: "avatar-uploader",
              attrs: {
                action: _vm.ossInfo.ossSignature.host,
                "show-file-list": false,
                "auto-upload": false,
                "list-type": "picture-card",
                "on-progress": _vm.uploadVideoProcess,
                "on-change": _vm.handleChange2,
                data: _vm.fileDataObj2,
                accept: ".mp4"
              }
            },
            [
              _vm.videoUploadPercent == 0
                ? _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
                : _vm.videoUploadPercent > 0
                ? _c("el-progress", {
                    staticStyle: {
                      "margin-top": "30px",
                      width: "178px",
                      height: "178px"
                    },
                    attrs: {
                      type: "circle",
                      percentage: _vm.videoUploadPercent
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "video",
            {
              staticClass: "float-l",
              staticStyle: { width: "70%" },
              attrs: { src: _vm.ossVideoUrl, controls: "controls" }
            },
            [_vm._v("您的浏览器不支持视频播放")]
          ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-left": "20px",
            display: "flex",
            "justify-content": "flex-start",
            "align-items": "flex-end"
          }
        },
        [
          _vm.ossVideoUrl == ""
            ? _c("span", [_vm._v("支持mp4格式，≤500MB")])
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.backVideo }
                },
                [_vm._v("删除")]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }