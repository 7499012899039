var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.formTitle, width: "600px", visible: _vm.formVisible },
      on: {
        "update:visible": function($event) {
          _vm.formVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.formRules,
            model: _vm.form,
            size: "small",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品牌", prop: "brand" } },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  clearable: "",
                  codeType: "brand",
                  placeholder: "品牌"
                },
                model: {
                  value: _vm.form.brand,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "brand", $$v)
                  },
                  expression: "form.brand"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面URL", prop: "url" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.url,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语言", prop: "lang" } },
            [
              _c("dataSelect", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", codeType: "lang", placeholder: "语言" },
                model: {
                  value: _vm.form.lang,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "lang", $$v)
                  },
                  expression: "form.lang"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面标题1", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  oninput: "if(value.length>200)value=value.slice(0,200)"
                },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面关键字", prop: "keyword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  oninput: "if(value.length>200)value=value.slice(0,200)"
                },
                model: {
                  value: _vm.form.keyWord,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "keyWord", $$v)
                  },
                  expression: "form.keyWord"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  oninput: "if(value.length>1000)value=value.slice(0,1000)"
                },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.formVisible = false
                }
              }
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.doCreate()
                }
              }
            },
            [_vm._v(" 确认 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }