<template>
    <el-dialog :title="formTitle" :visible.sync="formVisible" v-if="formVisible" :close-on-click-modal="false">
        <el-form
            ref="dataForm"
            :rules="formRules"
            :model="form"
            label-position="left"
            size="small"
            label-width="100px"
        >
            <el-form-item label="国家名称" prop="countryCode">
                <!-- <el-select v-model="form.countryCode" placeholder="请选择国家" @change="changeCountry">
                    <el-option v-for="item in counrtys" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                </el-select> -->
                <dataSelect
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.countryCode"
                    clearable
                     @change="changeCountry"
                    :codeType="'country_all'"
                    placeholder="请选择国家"
                ></dataSelect>
            </el-form-item>
            <el-form-item prop="icon" label="图标">
                <el-col :span="8">
                    <!-- <ossUploader
                        v-if="ossSignature.host != ''"
                        ref="mainImageUploader"
                        :ossSignature="ossSignature"
                        :limit="1"
                        uploaderId="mainimage"
                        @fileListChange="fileListChange"
                    /> -->
                    <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl" class="imgUpload"></imgUpload>
                    <!-- <imgUpload ref="imageUpload" :img="imageUrl && ossHost + imageUrl" @event="changeUrl"></imgUpload> -->
                </el-col>
                <el-col :span="10"  style="margin-top:120px">
                        <span>建议尺寸:466*280 <=200KB</span>
                    </el-col>
            </el-form-item>
            <el-form-item label="语言" prop="lang">
                <dataSelect
                    :disabled="true"
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.lang"
                    clearable
                    :codeType="'lang'"
                    placeholder="语言"
                ></dataSelect>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
                <el-checkbox-group v-model="checkedBrand" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="(item, index) in brands" :label="item.code" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="是否启用" prop="status">
                <el-switch v-model="form.status"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false"> 取消 </el-button>
            <el-button type="primary" @click="doCreate()"> 确认 </el-button>
        </div>
    </el-dialog>
</template>

<script>
import DataSelect from '@/components/common/DataSelect';
import imgUpload from '@/views/country/imgUpload';
function initFormBean() {
    const formBean = {
        countryId: null,
        countryCode: null,
        icon: null,
        lang: null,
        status: null
    };
    return formBean;
}

export default {
    name: 'CountryAdd',
    components: {
        DataSelect,
        imgUpload
    },
    data() {
        var validateCountry = (rule, value, callback) => {
            if (this.form.countryCode === '' || this.form.countryCode == null) {
                callback(new Error('请选择国家'));
            } else {
                callback();
            }
        };
        var validateBrand = (rule, value, callback) => {
            if (this.checkedBrand.length == 0) {
                callback(new Error('请选择品牌'));
            } else {
                callback();
            }
        };
        var validateIcon = (rule, value, callback) => {
            if (this.form.icon === '' || this.form.icon == null) {
                callback(new Error('请上传图片'));
            } else {
                callback();
            }
        };
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            form: {},
            formVisible: false,
            formTitle:'',
            formRules: {
                countryCode: [{ validator: validateCountry, trigger: 'change' }],
                brand: [{ validator: validateBrand, trigger: 'change' }],
                // icon: [{ validator: validateIcon, trigger: 'change' }]
            },
            counrtys: [],
            langs: [],
            counrtylangs: [],
            checkedBrand: [],
            brands: [],

            countryWatch: null,
            imageUrl: '',
            reloadimageUrl:this.imageUrl
        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        changeUrl(data) {
            console.log("===",data)
            this.form.icon = data;
            console.log("===",this.form.icon)
        },
        handleCheckedCitiesChange(value) {
            this.checkedBrand = value;
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'country_all' }).then((res) => {
                if (res != null) {
                    _this.counrtys = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null) {
                    _this.langs = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) {
                    _this.counrtylangs = res;
                }
            });


            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brands = res;
                }
            });
        },
        changeCountry(e) {
            console.log(e);
            for(var item of this.counrtylangs){
                if(item.code == e){
                    this.form.lang = item.name;
                }
            }
            console.log(this.form.lang)
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.form = initFormBean();
            this.imageUrl = "";
            this.checkedBrand = [];
            this.countryWatch = '';
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.formTitle = '新建国家信息'
            this.resetForm();
            this.formVisible = true;
            this.imageUrl=""
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
            //默认全选
            for(var item of this.brands){
                this.checkedBrand.push(item.code);
            }
        },
        handleEdit(){
            this.formTitle = '编辑国家信息'
            this.resetForm();
            this.formVisible = true;
            this.imageUrl=""
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        /**
         * 执行新建操作
         */
        doCreate() {
            console.log(this.form.icon);
            if(this.form.icon == '' || this.form.icon == null){
                this.$message({
                    showClose: false,
                    message: '图片必传',
                    type: 'warning'
                });
                return;
            }
            this.form.brandCodeList = this.checkedBrand;
            this.$refs['dataForm'].validate().then(() => {
                console.log("新建国家信息表图片地址",this.imageUrl);
                if (this.form.countryId == null) {
                    this.$store.dispatch('addCountry', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success('操作成功');
                    });
                } else {
                    this.$store.dispatch('updateCountry', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success('操作成功');
                    });
                }
            });
        }
    },
    watch: {
        // countryWatch: {
        //     // 子级下拉框的监听对象
        //     deep: true,
        //     handler: function (newval) {
        //         // 监听内容如下
        //         this.form.countryCode = newval;
        //         this.changeCountry(newval); // 值改变后的事件处理
        //     }
        // },
        checkedBrand: {
            // 子级下拉框的监听对象
            deep: true,
            handler: function (newval) {
                // 监听内容如下
                this.handleCheckedCitiesChange(newval); // 值改变后的事件处理
            }
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
