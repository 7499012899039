<template>
    <div>
        <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
            <el-menu-item v-for="(item, index) in langs" :key="item.index" :index="index.toString()">
                {{ item.name }}
            </el-menu-item>
        </el-menu>
        <!-- <div
            v-for="(item, index) in langs"
            :key="item[defaultOption.value]"
            :index="index.toString()"
            v-show="contentIndex == index.toString()"
        >
         <div :id="'editorContent' + defaultKey + item[defaultOption.value]"></div>
           
        </div> -->
        <quill-editor ref="myTextEditor" v-model="currentDetail.content" :options="editorOption"></quill-editor>
    </div>
</template>
<script>
import E from 'wangeditor';
import X2JS from 'x2js'; //解析xml
import SparkMD5 from 'spark-md5';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
    quillEditor
} from 'vue-quill-editor';
export default {
    name: 'translateEditor',
    components: {
        quillEditor
    },
    props: {
        defaultKey: {
            type: String,
            default: 'cotent'
        },
        defaultOption: {
            type: Object,
            default: () => {
                return { label: 'label', value: 'value' };
            }
        },
        contentData: {
            type: Object,
            default: () => {
                return { langs: [], details: {}, ossSignature: '' };
            }
        }
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            details: [],
            //富文本参数
            editorContent: {},
            contentIndex: '0',
            langs: [],
            currentLang: '',
            defaultFileName: '',
            itemList:[],
            currentDetail: { content: '', lang: '' },
            editorOption: {
                placeholder: ''
            },
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.langs = [];
        let index = 0;
        this.$store.state.common.lang.forEach(e => {
            this.langs.push({ name: e.name, index: index, code: e.code });
            index++;
        });
        this.details = this.contentData.details;
        this.contentIndex = '0';
        if (this.langs && this.langs.length > 0) {
            this.currentLang = this.langs[0].code;
        }
        for (let detail of this.details) {
            if (this.currentLang == detail.lang) {
                this.currentDetail = detail;
                break;
            }
        }
        if ('' === this.currentDetail.lang) {
            this.currentDetail.lang = this.currentLang;
            this.details.push(this.currentDetail);
        }
    },
    methods: {
      handleContentSelect(key, keyPath) {
            this.contentIndex = key;
            this.currentLang = this.langs[key].code;
            this.currentDetail = { content: '', lang: '' };
            for (let detail of this.details) {
                if (this.currentLang == detail.lang) {
                    this.currentDetail = detail;
                    break;
                }
            }
            if ('' === this.currentDetail.lang) {
                this.currentDetail.lang = this.currentLang;
                this.details.push(this.currentDetail);
            }
            this.$emit('langChange', this.currentLang);
        }
    }
};
</script>
