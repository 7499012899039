<template>
    <div>
        <el-form :model='formData' :rules='rules' ref='formData'>
 

            <el-form-item label="邮件模板编号" prop="emailTemplateCode" :label-width="formLabelWidth">
                
                <el-select v-model="formData.emailTemplateCode" class="filter-item" style="width: 300px; " clearable placeholder="请选择模板类型" @change='handleEmailTemplateCodeChange'>

                    <el-option v-for="item in emailTemplateCodeDicts" :key="item.code" :label="item.name" :value="item.code">
                    </el-option>      

                </el-select>
 
            </el-form-item>


            <el-form-item label="发送对象" prop="sendEmail"   :label-width="formLabelWidth"   >
              
                <el-input v-model="formData.sendEmail" style="width: 88%; " readonly  class='input-width' ></el-input>

                <el-button @click="handleExport" :loading="loading" style="margin-left: 10px;" >导出选中</el-button>        
  
            </el-form-item>


            <el-form-item label="模板语言" prop="lang" :label-width="formLabelWidth">
                
                <el-select v-model="formData.lang" class="filter-item" clearable style="width: 300px; " placeholder="请选择模板语言">
                    <el-option v-for="item in langDicts" :key="item.code" :label="item.name" :value="item.code">
                    </el-option>      
                </el-select>
 
            </el-form-item>



            <el-form-item label="邮件标题" prop="emailTitle"   :label-width='formLabelWidth' >
                <el-input v-model="formData.emailTitle" :maxlength="200" show-word-limit class='input-width' placeholder='请填写邮件标题（请填入对应翻译）'></el-input>
            </el-form-item>

            <el-form-item label="邮件内容" prop="emailContent"  :label-width='formLabelWidth' >
                <el-input v-model="formData.emailContent" type="textarea" :rows="5" class='input-width' placeholder='请填写邮件内容（请填入对应翻译）'></el-input>
            </el-form-item> 

            <el-form-item label="备注" prop="remark"  :label-width='formLabelWidth' >
                <el-input v-model="formData.remark" type="textarea" :rows="5" :maxlength="500" show-word-limit class='input-width' placeholder='请填写备注'></el-input>
            </el-form-item> 
 
 
           
        </el-form>

 
        <div  slot="footer" class='dialog-footer'>
            <el-button   type='primary'  :loading="loading" @click='onSubmit'>确 定</el-button>
            <el-button   @click='closeDialog(false)'>取 消</el-button>     
        </div>

       
    </div>
</template>
<script>
 
 

export default {
    components: {
    },
    props: {
        userCommentList: {
            type: Array,
            default: () => {
                return []
            }
        },
        //  是否批量回复
        replysFlag: {
            type:Boolean,
            default: false
         }
    },
    filters: {
    },
    data() {
        return {
            emailTemplateCodeDicts: [{
                id: '1',
                name: 'AigostarCustomerServiceResponse',
                code: 'AigostarCustomerServiceResponse'
            }, {
                id: '2',
                name: 'NoblezaCustomerServiceResponse',
                code: 'NoblezaCustomerServiceResponse'
            }],
            langDicts: [],
            defaultFormData: {
                emailTemplateCode: null,
                sendEmail: null,
                lang: null,
                emailTitle: null,
                emailContent: null,
                remark: null,
                userCommentDtoList: null
            },
            formData: {
                emailTemplateCode: null,
                sendEmail: null,
                lang: null,
                emailTitle: null,
                emailContent: null,
                remark: null,
                userCommentDtoList: null
            },
            formLabelWidth: '120px',
            loading: false,
            rules: {
                emailTemplateCode: [
                    {
                        required: true, message: '请选择模板类型', trigger: 'change'
                    }
                ],
                sendEmail:[
                    {
                        required: true, message: '发送对象为空', trigger: 'blur'
                    } 
                ],
                lang: [
                    {
                        required: true, message: '请选择模板语言', trigger: 'change'
                    }
                ],
                emailTitle:[
                    {
                        required: true, message: '请填写邮件标题（请填入对应翻译）', trigger: 'blur'
                    }
                ],
                emailContent:[
                    {
                        required: true, message: '请填写邮件内容（请填入对应翻译）', trigger: 'blur'
                    }
                ],
                remark:[
                    {
                        required: true, message: '请填写备注', trigger: 'blur'
                    }
                ],
                
            },

           
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init(){
            // console.log(this.userCommentList)
            this.formData.sendEmail = this.userCommentList.map(item=>(item.email)).join(',');   

            this.formData.userCommentDtoList = []
            this.userCommentList.forEach(item=>{
                let element = {
                    userCommentId: item.userCommentId,
                    email: item.email
                }
                this.formData.userCommentDtoList.push(element)
            })

        },
      
        onSubmit() {

            console.log('formData',this.formData)
            this.$refs['formData'].validate((valid,Object) => {
                if (valid) {
                    this.$confirm( '是否提交数据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.loading=true   
                        this.$store.dispatch('addUserReply', this.formData).then((res) => {
                            this.$message({
                                message: '提交成功',
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDialog(true);
                        }).finally(() => {
                            this.loading = false;
                        });
  
                    }).catch(() => {
                    });
                } else {
                    this.$message({
                        message: '验证失败，请先输入必填项',
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });

        },
         
        closeDialog(status) {
            this.$emit('closeEmailReplyDialog',status,this.replysFlag);
        },
        // 导出发送对象
        handleExport() {
            let sendEmails = this.userCommentList.map(item=>(item.email));

            let data = {
                sendEmails: sendEmails,
                fileName: '发送对象数据.xlsx'
            }
           
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                this.$store.dispatch('exportUserReplySendEmail', data).then((res) => {
                    this.$message({type: 'success',message: '导出成功'});
                  
                }).finally(() => {
                    this.loading = false;
                });
            }).catch(() => {
                this.$message({type: 'info',message: '已取消'});
            });
        },
        // 选择邮件模板编号
        handleEmailTemplateCodeChange(val){
            this.langDicts = []
            // console.log(val)
            let data =  {
                templateCode: val
            }
            this.$store.dispatch('getUserReplyEmailTemplateLangs', data).then((res) => {
                // console.log(res)
                this.langDicts = res;
            }) 
        }
    }
};
</script>
<style scoped>

 

</style>
<style>

</style>