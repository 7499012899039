var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-lx-redpacket_fill" }),
              _vm._v(" 支持作者\n            ")
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "plugins-tips" }, [
        _vm._v(
          "如果该框架对你有帮助，那就请作者喝杯饮料吧！加微信号linxin_20探讨问题。"
        )
      ]),
      _c("div", [
        _c("img", {
          attrs: { src: "https://lin-xin.gitee.io/images/weixin.jpg" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }