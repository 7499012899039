var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.contentIndex, mode: "horizontal" },
          on: { select: _vm.handleContentSelect }
        },
        _vm._l(_vm.langs, function(item, index) {
          return _c(
            "el-menu-item",
            { key: item.index, attrs: { index: index.toString() } },
            [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
          )
        }),
        1
      ),
      _c("quill-editor", {
        ref: "myTextEditor",
        attrs: { options: _vm.editorOption },
        model: {
          value: _vm.currentDetail.content,
          callback: function($$v) {
            _vm.$set(_vm.currentDetail, "content", $$v)
          },
          expression: "currentDetail.content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }