<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="query.title"
                placeholder="名称"
                style="width: 180px"
                class="filter-item"
                @keyup.enter.native="handleQuery"
                oninput="value=value.replace(/[%]/g,'')"
            />
            <dataSelect
                style="width: 180px; margin-left: 10px"
                class="filter-item"
                v-model="query.certificateType"
                clearable
                :codeType="'certificate'"
                placeholder="类型"
            ></dataSelect>
            <dataSelect
                style="width: 180px; margin-left: 10px"
                class="filter-item"
                v-model="query.brandCode"
                clearable
                :codeType="'brand'"
                placeholder="品牌"
            ></dataSelect>
            <el-select v-model="query.status" placeholder="是否启用" clearable style="width: 180px; margin-left: 10px" class="filter-item">
                <el-option label="是" value="true"></el-option>
                <el-option label="否" value="false"></el-option>
            </el-select>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                查询
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small">
                重置
            </el-button>
        </div>
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" icon="el-icon-edit" @click="handleCreate">
                添加
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" prop="certificateId" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="类型" prop="certificateType" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.certificateType" :codeType="'certificate'"></dataText>
                </template>
            </el-table-column>
            <el-table-column label="品牌" prop="certificateBrand" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.certificateBrand" :key="index">
                        {{ item.brandCode }}
                        <span v-if="scope.row.certificateBrand.length != 1 && index != scope.row.certificateBrand.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="名称" prop="title" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="logo" label="图标" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" v-if="scope.row.logo != ''" :src="scope.row.logo" fit="fill"></el-image>
                </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="imageUrl" label="图片" align="center">
                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="scope.row.imageUrl"
                        fit="fill"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="更新时间" prop="updateTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :render-header='renderSortHeader' prop="sort" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.sort"
                        :min="0"
                        οnkeyup="value=value.replace(/[^\d]/g,'')"
                        oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                        @keyup.enter.native="putSortCertificate(scope.row)"
                        placeholder="请输入内容"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="是否启用" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <!-- <span v-if="scope.row.status">启用</span>
                    <span v-if="!scope.row.status">禁用</span> -->
                    <el-switch v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addCertificate"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item label="类型" prop="certificateType">
                    <el-radio-group v-model="addCertificate.certificateType" @change="certificateTypeChange()">
                        <el-radio label="Certificate">证书</el-radio>
                        <el-radio label="Image">图片</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="品牌" prop="brandCodes">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="brandCodes"
                        clearable
                        multiple
                        :codeType="'brand'"
                        placeholder="品牌"
                    ></dataSelect>
                </el-form-item>
                <el-form-item label="展示国家" prop="countryCodes">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <el-checkbox-group v-model="countryCodes" @change="handleCheckedCitiesChange" style="width: 700px">
                        <el-checkbox v-for="country in countrysDicts" :label="country.code" :key="country.code"
                            >{{ country.name }}({{ country.code }})</el-checkbox
                        >
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item prop="imageUrl" label="图标" v-if="addCertificate.certificateType == 'Certificate'">
                    <el-col :span="14">
                        <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl"> </imgUpload>
                    </el-col>
                </el-form-item>
                <el-form-item prop="imageUrl" label="图片">
                    <el-col :span="14">
                        <imgUpload ref="imageUpload2" :img="imageUrl2" @event="changeUrl2"> </imgUpload>
                    </el-col>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="addCertificate.sort" :min="0" style="width: 100%" oninput="value=value.replace(/^\.+|[^\d]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="status">
                    <el-switch v-model="addCertificate.status" active-color="#13ce669"></el-switch>
                </el-form-item>
                <!-- </div> -->

                <el-form-item label="多语言配置" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>名称：</span>
                        <el-input style="margin-top: 20px; width: 450px" v-model="title" placeholder="请输入名称" label="名称"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> 取消 </el-button>
                <el-button type="primary" @click="handleSubmit()"> 确认 </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/components/common/imgUpload/imgUpload';
import X2JS from 'x2js'; //解析xml

function initAddBean() {
    const addBean = {
        certificateId: null,
        imageUrl: '',
        logo: '',
        sort: 0,
        status: true,
        certificateType: 'Certificate',
        certificateLang: [],
        certificateBrand: [],
        certificateCountry: []
    };
    return addBean;
}

export default {
    name: 'Certificate',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        imgUpload
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error('请选择时间范围'));
            }
        };
        return {
            certificateType: 'Certificate',
            formTitle: '',
            old: initAddBean(),
            addCertificate: initAddBean(),
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],

            //多选
            brandCodes: [],
            countryCodes: [],

            query: {
                page: 1,
                limit: 10,
                title: '',
                status: null,
                certificateType: null,
                brandCode: null,
                desc:true
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,
            isIndeterminate: true,
            checkAll: false,
            countryLang: [],
            //图标链接
            imageUrl: '',
            //图片链接
            imageUrl2: '',
            //富文本内容的主要参数
            contentIndex: 'zh',
            langsArr: [],
            titleArr: [],
            title: '',
            langs: [],
            formRules: {
                articleType: [{ required: true, message: '请选择栏目', trigger: 'blur' }],
                certificateType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                startTime: [{ validator: checkDateTime, required: true }]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '这个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '上一个月',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '今年',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        /**
         * 修改排序
         */
        putSortCertificate(row){
            const str = '^(\\d+)\\.(\\d{0}).*$';
            const reg = new RegExp(str);
            this.$store
                .dispatch('putSortCertificate', {
                    id: row.certificateId,
                    sort: row.sort ? row.sort : 0
                })
                .then((res) => {
                    this.doQueryList({ page: 1 });
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                });
        },
        //自定义label
        renderSortHeader() {
            return (
                <div>
                    <span class='sort-span' on-click={() => this.handleSort()}>排序 <span
                        class='el-icon-sort'></span></span>
                </div>
            );
        },
        //排序
        handleSort() {
            this.query.desc = !this.query.desc;
            setTimeout(() => {
                this.doQueryList({ page: 1 });
            });
        },
        /**
         * 重置多语言
         */
        initLangsArr() {
            for (var item of this.langs) {
                let param = {
                    lang: item.code,
                    title: ''
                };
                this.langsArr.push(param);
            }
        },
        init() {
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                }
            }
        },
        handleContentSelect(key, keyPath) {
            //赋值
            for (const key in this.langsArr) {
                if (this.langsArr[key].lang == this.contentIndex) {
                    this.langsArr[key].title = this.title;
                }
            }

            //更新富文本内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                }
            }
        },
        /**
         * 更改类型
         */
        certificateTypeChange() {
            //更换类型时清空图标的图片
            this.addCertificate.logo = '';
            this.imageUrl2 = '';
        },
        /**
         * 上传路径
         */
        changeUrl(data) {
            this.addCertificate.logo = data;
        },
        changeUrl2(data) {
            this.addCertificate.imageUrl = data;
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            if (this.query.certificateType == '') {
                this.query.certificateType = null;
            }
            if (this.query.brandCode == '') {
                this.query.brandCode = null;
            }
            this.$store.dispatch('getCertificatePage', this.query).then((res) => {
                this.list = res;
            });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteCertificate', { id: row.certificateId }).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle = '编辑';
            this.old = row;
            this.resetEditForm();
            for (const key in initAddBean()) {
                this.addCertificate[key] = this.old[key];
            }
            this.imageUrl = this.addCertificate.logo;
            this.imageUrl2 = this.addCertificate.imageUrl;
            console.log(this.addCertificate);
            this.addVisible = true;

            //品牌赋值
            let arr = [];
            for (const key in this.addCertificate.certificateBrand) {
                arr.push(this.addCertificate.certificateBrand[key].brandCode);
            }
            this.brandCodes = arr;

            //国家赋值
            let arr1 = [];
            for (const key in this.addCertificate.certificateCountry) {
                arr1.push(this.addCertificate.certificateCountry[key].countryCode);
            }
            this.countryCodes = arr1;

            //多语言赋值
            for (var item of this.addCertificate.certificateLang) {
                for (const key in this.langsArr) {
                    if (item.lang == this.langsArr[key].lang) {
                        this.langsArr[key].title = item.title;
                    }
                }
            }
            //赋值多语言信息列表
            this.init();
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.addCertificate = initAddBean();
            this.addCertificate.certificateLang = [];
            this.addCertificate.certificateBrand = [];
            this.addCertificate.certificateCountry = [];
            this.langsArr = [];
            this.title = '';
            this.contentIndex = 'zh';
            this.initLangsArr();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.isIndeterminate = false;
            this.addCertificate = initAddBean();
            this.addCertificate.certificateLang = [];
            this.addCertificate.certificateBrand = [];
            this.addCertificate.certificateCountry = [];
            this.imageUrl = '';
            this.imageUrl2 = '';
            this.contentIndex = 'zh';
            this.langsArr = [];
            this.brandCodes = [];
            this.countryCodes = [];
            this.title = '';
            this.initLangsArr();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = '新增';
            this.resetForm();
            this.addVisible = true;
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            //图片必传
            if (this.addCertificate.imageUrl == '') {
                this.$message({
                    showClose: false,
                    message: '图片必传',
                    type: 'warning'
                });
                return;
            }

            //类型为图标时，图标必传
            if (this.addCertificate.certificateType == 'Certificate') {
                if (this.addCertificate.logo == '') {
                    this.$message({
                        showClose: false,
                        message: '图标必传',
                        type: 'warning'
                    });
                    return;
                }
            } else {
                this.addCertificate.logo = '';
            }

            //当前选项多语言信息赋值
            for (const key in this.langsArr) {
                if (this.langsArr[key].lang == this.contentIndex) {
                    this.langsArr[key].title = this.title;
                }
            }

            this.addCertificate.certificateLang = this.langsArr;

            for (var item of this.addCertificate.certificateLang) {
                //英文内容必填
                if (item.lang == 'en') {
                    if (item.title == '') {
                        this.$message({
                            showClose: false,
                            message: '英语内容必填',
                            type: 'warning'
                        });
                        return;
                    }
                }
            }

            //品牌配置
            let arr3 = [];
            for (const key in this.brandCodes) {
                let brand = {
                    brandCode: this.brandCodes[key]
                };
                arr3.push(brand);
            }
            this.addCertificate.certificateBrand = arr3;

            //国家配置
            let arr4 = [];
            for (const key in this.countryCodes) {
                let country = {
                    countryCode: this.countryCodes[key]
                };
                arr4.push(country);
            }
            this.addCertificate.certificateCountry = arr4;

            console.log(this.addCertificate);

            if (this.formTitle == '新增') {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addCertificate', this.addCertificate).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.addCertificate);
                        this.$store.dispatch('editCertificate', this.addCertificate).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: '编辑失败',
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        //国家全选
        handleCheckAllChange(val) {
            if (val) {
                this.countryCodes = [];
                for (var item of this.countrysDicts) {
                    this.countryCodes.push(item.code);
                }
            } else {
                this.countryCodes = [];
            }

            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.countrysDicts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.countrysDicts.length;
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'country_ot' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query.title = '';
            this.query.status = null;
            this.query.certificateType = null;
            this.query.brandCode = null;
            this.doQueryList({ page: 1 });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
/* .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
} */
</style>
